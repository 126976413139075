'use strict';

module.exports = SearchController;

SearchController.$inject = ['$scope', '$rootScope', '$window', '$timeout', '$uibModal', 'UsersService', 'toaster', '$state', '$http', 'CookieLawService', 'StorageService', '$translate', '$cookies'];

function SearchController($scope, $rootScope, $window, $timeout, $uibModal, UsersService, toaster, $state, $http, CookieLawService, StorageService, $translate, $cookies) {

    /************************************************
     * VARIABLES
     ************************************************/
    var vm = this;
    vm.isHome = false;
    vm.isAuthenticated = false;
    vm.user = {};
    vm.isCollapsed = true;
    vm.searchobject = {};
    vm.date = '';
    vm.location = '';
    $scope.data = {};
    vm.language = '';
    vm.modalInstance = null;
    vm.whiteLogo = false;
    vm.selectedLanguage = null;

    var everywhere = angular.element(window.document);

    /************************************************
     * METHODS
     ************************************************/
    vm.signUp = signUp;
    vm.login = login;
    vm.logout = logout;
    vm.displayOffer = displayOffer;
    vm.noInternet = noInternet;

    everywhere.on('click', function(event) {
        var nav = document.getElementsByClassName('navbar-collapse')[0];
        var button = document.getElementsByClassName('navbar-toggle')[0];

        if(!nav.contains(event.target) 
            && !button.contains(event.target)) {
            vm.isCollapsed = true;
        }

        $scope.$apply();
    });

    $rootScope.online = navigator.onLine;

    $window.addEventListener("offline", function() {
        $rootScope.apply(function() {
            $rootScope.online = false;
        });
    }, false);

    $window.addEventListener("online", function() {
        $rootScope.apply(function() {
            $rootScope.online = true;
        });
    }, false);

    if(!$rootScope.online) {
        // var noi = document.getElementById("prueba111");
        $timeout(function() {
            angular.element('#nointernet').triggerHandler('click');
        });
    }

    /**
     * @name init
     * @description Initialize the controller
     */
    function init() {
        vm.isHome = window.location.href === baseURL;
        vm.isAuthenticated = !!$rootScope.currentUser;
        if (vm.isAuthenticated) 
            getUserInfo();

        vm.logoSrc = (vm.isHome || vm.isAuthenticated) ? 'logo.svg' : 'logo2.png';
        $scope.$on('cookieLaw.decline', function () {
            CookieLawService.decline();
            angular.element('.cl-banner').hide();
        });
    }

    function noInternet() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'assets/js/app/modal/noInternetModal.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 1,
            resolve: {
                data: function () {
                    return $scope.data;
                }.bind(this)
            }
        });
        modalInstance.result.then(function(data) {
        }).catch(function(res) {
            modalInstance.close();
        });
    }

    function displayOffer() {

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'assets/js/app/modal/premium-host.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 1,
            resolve: {
                data: function () {
                    return $scope.data; // Object.assign({}, $scope.data, {premium: 1});
                }.bind(this)
            }
        });
    }

    /**
     *
     * @param index
     */
    function signUp(index, premium) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'assets/js/app/modal/sign-up.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 1,
            resolve: {
                data: function () {
                    return Object.assign({}, $scope.data, {premium: premium, organizeFoodEvent: premium});
                }.bind(this)
            }
        });

        modalInstance.result.then(function (data) {
            //If the attribute cover_url exists then the user was registered from Facebook
            var signUpMethod = null;
            if (!!data.cover_url) signUpMethod = UsersService.signUpFacebook;
            else if (!!data['Paa']) signUpMethod = UsersService.signUpGoogle;
            else signUpMethod = UsersService.signUp;
            signUpMethod(data).then(
                function (response) {
                    if (response.data.data.response === 'success') {
                        toaster.pop("success", "Account created", "Your account was created successfully, please check your email.", 4000);
                    } else if (response.data.data.response === 'login') {
                        setTimeout(function () {
                            location.reload();
                        }, 500);
                    }
                },
                function (err) {
                    toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                }
            );
        }).catch(function (res) {
            modalInstance.close();
        });
    }

    /**
     * @name login
     * @description Shows the login modal
     * @param index
     */
    function login(index) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'assets/js/app/modal/login.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 1,
            resolve: {
                data: function () {
                    return $scope.data;
                }.bind(this)
            }
        });

        modalInstance.result.then(function (data) {
            if (!!data.recoveryEmail) {
                UsersService.sendResetToken(data.recoveryEmail).then(
                    function (response) {
                        toaster.pop("success", "Password Reset", "Instructions were sent successfully, please check your email.", 4000);
                    },
                    function (err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                )
            } else {
                UsersService.isVerified(data).then(
                    function (response) {
                        if (response.data.data.response === 'confirmed') {
                            var loginMethod = null;
                            if (!!data.cover_url) loginMethod = UsersService.signUpFacebook;
                            else if (!!data['Paa']) loginMethod = UsersService.signUpGoogle;
                            else loginMethod = UsersService.login;
                            loginMethod(data).then(
                                function (response) {
                                    if (response.data.data.response === 'success' || response.data.data.response === 'login') {
                                        var token = response.data.data.token;
                                        StorageService.setItem('token', token);
                                        if (response.data.data.user.unread_msg_cnt !== undefined && response.data.data.user.unread_msg_cnt > 0)
                                            StorageService.setItem('showNewMessageNotification', true);
                                        else
                                            StorageService.setItem('showNewMessageNotification', false);
                                        setTimeout(function () {
                                            var redirectUrl = localStorage.getItem('redirect');
                                            console.log('redirectUrl => ', redirectUrl);
                                            localStorage.removeItem('redirect');
                                            if(!!redirectUrl) window.location.href = redirectUrl;
                                            else location.reload();
                                        }, 500);
                                    } else {
                                        toaster.pop("warning", "Incorrect info", "Please check you email and password and try again.", 4000);
                                    }
                                },
                                function (err) {
                                    toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                                }
                            );
                        } else if (response.data.data.response === 'no_confirmed') {
                            toaster.pop('error', "Error", "Your email did not verified, please try email verification.", 4000);
                        } else {
                            toaster.pop('error', "Error", "Your email did not registered, please try sign up.", 4000);
                        }

                    }
                );
            }
        }).catch(function (res) {
            modalInstance.close();
        });
    }

    /**
     * @name logout
     * @description Destroys the user session
     * @returns {*}
     */
    function logout() {
        StorageService.deleteItem('token');
        window.location.href = baseURL;
    }

    /**
     * @name getUserInfo
     * @description Gets user info
     */
    function getUserInfo() {
        UsersService.getUserInfo().then(_setUserInfo);

        /**
         * @name _setUserInfo
         * @description gets the user info
         * @param response
         * @private
         */
        function _setUserInfo(response) {
            vm.user = response.data.data.user;
            vm.user.image_path = !!vm.user.image_path ? vm.user.image_path : baseURL + 'assets/images/dashboard/no_avatar-xlarge.jpg';
        }
    }

    //Checking if current location is the homepage
    $rootScope.$on("$locationChangeStart", function (event, next, current) {
        vm.isAuthenticated = !!$rootScope.currentUser;
        vm.isCollapsed = true;
        if (vm.isAuthenticated)
            getUserInfo(false);
        vm.isHome = (baseURL === next.toString());
        vm.logoSrc = vm.isHome ? 'logo.svg' : 'logo2.png';
    });

    $rootScope.$watch('currentUser', function () {
        vm.isAuthenticated = !!$rootScope.currentUser;
        vm.isCollapsed = true;
        if (vm.isAuthenticated) {
            getUserInfo(true);
            if (StorageService.getItem('showNewMessageNotification') == 'true') {
                toaster.pop("success", "Unread Message", "You have unread message(s).", 4000);
                StorageService.setItem('showNewMessageNotification', false);
            }

            //vm.selectedLanguage = $rootScope.currentUser.language;
            //vm.selectedLanguage = "en";
        }
    }, true);

    $rootScope.$on('non-authenticate', function () {
        vm.login(1);
    });

    $rootScope.$on('afterverification', function () {
        vm.afterEmailVerificationLogin();
    });
    $rootScope.$on('linkSignUp', function () {
        vm.signUp(1);
    });
    $rootScope.$on('linkPremiumSignUp', function () {
        vm.signUp(1, 1);
    });

    $rootScope.$on('linkLogin', function () {
        vm.login(1);
    });

    $scope.$watch(function watch(scope) {
        return vm.searchobject;
    }, function handle() {
        if (!vm.searchobject.lat || !vm.searchobject.lng)
            return;
        $state.go("search_hosts", {
            city: vm.location,
            lat: vm.searchobject.lat,
            lng: vm.searchobject.lng,
            distance: 50,
            date: null,
            cat: ''
        });
    }, true);

    $rootScope.$watch('globalSettings', function () {
        if (!!$rootScope.globalSettings) {
            vm.whiteLogo = !!$rootScope.globalSettings.whiteLogo;
        }
    }, true);

    init();
}