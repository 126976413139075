'use strict';

module.exports = UserController;

UserController.$inject = ['UsersService', 'toaster', 'FileUploader', '$uibModal', '$scope', '$rootScope', '$stateParams', '$location', '$state', '$sce', 'StorageService'];

function UserController(UsersService, toaster, FileUploader, $uibModal, $scope, $rootScope, $stateParams, $location, $state, $sce, StorageService) {

    /******************
     * VARIABLES
     ******************/
    var vm = this;
    vm.user = null;
    vm.isCollapsed = true;
    vm.token = null;
    vm.files = [];
    vm.currentDate = moment().format("DD-MM-YYYY");
    vm.today = moment();
    vm.user = {};
    var token = StorageService.getItem('token');
    if (!token) token = "";
    var UPLOAD_URL = baseURL + 'Users/saveImage/' + token;
    var uploader = $scope.uploader = new FileUploader({
        url: UPLOAD_URL
    });
    vm.userLoaded = false;
    vm.savingData = false;
    vm.send = false;
    vm.activeNav = 'tab-1';
    vm.paymentOption = '';
    vm.selectCountry = false;
    vm.paymentCountry = '';
    vm.bankSettings = {};
    vm.stripeAccount = {};
    vm.paypalaccount = {};
    vm.resetPayment = false;
    vm.checkobject = {};
    vm.languageSettings = {
        template: '{{option}}',
        enableSearch: false,
        styleActive: true
    };

    vm.user.languages = [];
    vm.user.password = "";
    vm.user.confirm_password = "";
    vm.user.country_code = 'USA(+1)';
    vm.passwordError = false;

    vm.languages = ["English", "French", "Italian", "German", "Portuguese", "Spanish", "Afrikanns", "Albanian", "Arabic", "Armenian", "Basque", "Bengali", "Bulgarian", "Catalan", "Cambodian", "Chinese (Mandarin)", "Croation", "Czech", "Danish", "Dutch", "Estonian", "Finnish", "Georgian", "Greek", "Gujarati", "Hebrew", "Hindi", "Hungarian", "Icelandic", "Indonesian", "Irish", "Japanese", "Javanese", "Korean", "Latin", "Latvian", "Lithuanian", "Macedonian", "Malay", "Malayalam", "Maltese", "Maori", "Marathi", "Mongolian", "Nepali", "Norwegian", "Persian", "Polish", "Punjabi", "Quechua", "Romanian", "Russian", "Samoan", "Serbian", "Slovak", "Slovenian", "Swahili", "Swedish" , "Tamil", "Tatar", "Telugu", "Thai", "Tibetan", "Tonga", "Turkish", "Ukranian", "Urdu", "Uzbek", "Vietnamese", "Welsh", "Xhosa"];

    
    // FILTERS
    uploader.filters.push({
        name: 'imageFilter',
        fn: function (item, options) {
            var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        }
    });
    // CALLBACKS
    uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {
        //console.info('onWhenAddingFileFailed', item, filter, options);
    };
    uploader.onAfterAddingFile = function (fileItem) {
        console.info('onAfterAddingFile', fileItem);
    };
    uploader.onAfterAddingAll = function (addedFileItems) {
        //console.info('onAfterAddingAll', addedFileItems);
    };
    uploader.onBeforeUploadItem = function (item) {
        //console.info('onBeforeUploadItem', item);
    };
    uploader.onProgressItem = function (fileItem, progress) {
        //console.info('onProgressItem', fileItem, progress);
    };
    uploader.onProgressAll = function (progress) {
        //console.info('onProgressAll', progress);
    };
    uploader.onSuccessItem = function (fileItem, response, status, headers) {
        console.info('onSuccessItem', fileItem, response, status, headers);
    };
    uploader.onErrorItem = function (fileItem, response, status, headers) {
        //console.info('onErrorItem', fileItem, response, status, headers);
    };
    uploader.onCancelItem = function (fileItem, response, status, headers) {
        //console.info('onCancelItem', fileItem, response, status, headers);
    };
    uploader.onCompleteItem = function (fileItem, response, status, headers) {
        //console.info('onCompleteItem', fileItem, response, status, headers);
    };
    uploader.onCompleteAll = function () {
        toaster.pop("success", "User updated", "Your profile image has been updated.", 4000);
        init();
    };
    /******************
     * METHODS
     ******************/
    vm.saveUser = saveUser;
    vm.goToHost = goToHost;
    vm.goToUser = goToUser;
    vm.setView = setView;
    vm.selectCountryPayout = selectCountryPayout;
    vm.verifyCountry = verifyCountry;
    vm.saveStripeAccount = saveStripeAccount;
    vm.saveBankAccount = saveBankAccount;
    vm.savePayPalAccount = savePayPalAccount;
    vm.changePaymentMethod = changePaymentMethod;
    vm.updatePassword = updatePassword;
    vm.checkPassword = checkPassword;
    vm.renderHtml = renderHtml;
    vm.openModal = openModal;
    vm.displayDeleteModal = displayDeleteModal;

    /**
     * @name init
     * @description initializes the controller
     */
    function init() {
        if (!!$stateParams.id) {
            UsersService.showUserbyId($stateParams.id).then(_getUserInfo);
        } else {
            UsersService.getUserInfo().then(_setUserInfo);
            getParams();
        }

        /**
         * @name _setUserInfo
         * @description gets the user info
         * @param response
         * @private
         */
        function _setUserInfo(response) {
            vm.user = response.data.data.user;
            vm.user.languages = !!vm.user.languages ? JSON.parse(vm.user.languages) : [];
            //$rootScope.currentUser = response.data.data.user;
            if (!!vm.user.stripeaccount) {
                vm.paymentOption = 'Stripe';
                vm.user.stripeaccount = vm.user.stripeaccount[0];
            } else if (!!vm.user.bankaccount) {
                vm.paymentOption = 'Bank';
                vm.user.bankaccount = vm.user.bankaccount[0];
            } else if (!!vm.user.paypalaccount) {
                vm.paymentOption = 'PayPal';
                vm.user.paypalaccount = vm.user.paypalaccount[0];
            } else {
                vm.paymentOption = '';
            }
            vm.user.image_path = (!!response.data.data.user.image_path) ? response.data.data.user.image_path : baseURL + 'assets/images/dashboard/no_avatar-xlarge.jpg';
            vm.user.birthdate = moment(vm.user.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY');
            vm.user.lat = 0;
            vm.user.lng = 0;
            vm.user.news = vm.user.news === '1';
            vm.userLoaded = true;
        }

        /**
         * @name _getUserInfo
         * @description gets the user info
         * @param response
         * @private
         */
        function _getUserInfo(response) {
            if (!response.data.data.user) {
                $state.go("home");
            } else {
                vm.user = response.data.data.user;
                vm.user.languages = !!vm.user.languages ? JSON.parse(vm.user.languages) : [];
                vm.userLoaded = true;
            }
        }

    }

    /**
     * @modal open
     *
     */

    function openModal() {

        return $scope.modalInstance = $uibModal.open({
            templateUrl: 'assets/js/app/modal/profile_setting.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            resolve: {
                data: function () {
                    return $scope.data;
                }.bind(this)
            }
        });
    }

    function displayDeleteModal() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'assets/js/app/modal/deleteUserModal.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 1,
            resolve: {
                data: function () {
                    return $scope.data;
                }.bind(this)
            }
        });
        modalInstance.result.then(function () {
            UsersService.deleteUser(vm.user)
                .then(
                    function (response) {
                        if (response.data.data.response === 'success') {
                            toaster.pop("success", "User Deleted!", "Your account has been deleted successfully.", 4000);
                        }
                        setTimeout(function(){
                            StorageService.deleteItem('token');
                            window.location.href = baseURL;
                        }, 1000);
                    },
                    function (err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                );
        });
    }


    function getParams() {
        var params = $location.search();
        if (_.isEmpty(params))
            return;
        if (!!params.code) {
            UsersService.getStripePermissions(params.code).then(_success);

            function _success(response) {
                if (!!response.data.data.stripe_details.error) {
                    toaster.pop("warning", "Error", response.data.data.stripe_details.error_description, 4000);
                } else if (!!response.data.data.stripe_details.stripe_user_id) {
                    vm.user.stripeaccount = {
                        'id': '',
                        'account_id': response.data.data.stripe_details.stripe_user_id
                    };
                    UsersService.saveStripeAccount(vm.user.stripeaccount).then(_success, _error);

                    function _success(response) {
                        if (response.data.data.response === 'success') {
                            toaster.pop("success", "User updated", "Your Stripe account has been saved.", 2000);
                            setTimeout(function () {
                                var newUrl = baseURL + 'users/settings';
                                window.location.href = newUrl;
                            }, 2000);
                        }
                    }

                    function _error(err) {
                        console.log(err);
                    }
                } else {
                    toaster.pop("warning", "Error", "Something went wrong, please try again.", 4000);
                }
            }
        } else {
            toaster.pop("warning", "Error", "The user has denied the request.", 4000);
        }

    }

    /**
     * @saveuser
     * @description saves the user info
     */
    function saveUser(isValid) {
        if (isValid === false) {
            alert("Something is wrong");
            return;
        }
        vm.user.news = !!vm.user.news ? '1' : '0';
        UsersService.saveUser(vm.user).then(
            function (response) {
                if (response.data.data.response === 'success') {
                    toaster.pop("success", "User updated", "Your account info has been updated.", 4000);
                    StorageService.deleteItem('token');
                    token = response.data.data.token;
                    StorageService.setItem('token', token);
                    init();
                }
            },
            function (err) {
                toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
            }
        );
    }

    /**
     * @name goToHost
     * @description redirects the user to the event page
     * @param host
     */
    function goToHost(host) {
        $state.go("view_event", {
            event: host.id,
            date: vm.currentDate
        });
    }

    function goToUser(id) {
        $state.go("view_user", {
            id: id
        });
    }

    /**
     *
     * @param {type} _nav
     * @returns {undefined}
     */
    function setView(_nav) {
        vm.activeNav = _nav;
    }

    /**
     *
     * @returns {undefined}
     */
    function selectCountryPayout() {
        vm.selectCountry = true;
    }

    /**
     *
     * @returns {undefined}
     */
    function verifyCountry() {
        vm.loadingCountries = true;
        UsersService.getCountriesPayout().then(_success, _error);

        function _success(response) {
            var availableCountries = response.data.data.countries;
            var stripeAllowed = false;
            for (var i = 0; i < availableCountries.length; i++) {
                if (availableCountries[i] === vm.paymentCountry)
                    stripeAllowed = true;
            }
            vm.paymentOption = stripeAllowed ? 'Stripe' : 'PayPal';
            vm.loadingCountries = false;
        }

        function _error(err) {
            vm.loadingCountries = false;
            console.log(err);
        }
    }

    function saveStripeAccount() {
        vm.savingData = true;
        vm.user.stripeaccount.account_id = !!vm.user.stripeaccount.id ? vm.user.stripeaccount.id : '';
        UsersService.saveStripeAccount(vm.user.stripeaccount, vm.paymentCountry).then(_success, _error);

        function _success(response) {
            if (response.data.data.response === 'success') {
                toaster.pop("success", "User updated", "Your Stripe account has been saved.", 1000);
                setTimeout(function () {
                    location.reload();
                }, 1000);
            }
        }

        function _error(err) {
            console.log(err);
        }
    }

    function savePayPalAccount() {
        vm.savingData = true;
        vm.user.paypalaccount.id = !!vm.user.paypalaccount.id ? vm.user.paypalaccount.id : '';
        UsersService.savePayPalAccount(vm.user.paypalaccount).then(_success, _error);

        function _success(response) {
            if (response.data.data.response === 'success') {
                toaster.pop("success", "User updated", "Your PayPal account has been saved.", 1000);
                setTimeout(function () {
                    location.reload();
                }, 1000);
            }
        }

        function _error(err) {
            console.log(err);
        }
    }

    function saveBankAccount() {
        vm.savingData = true;
        vm.user.bankaccount.id = !!vm.user.bankaccount.id ? vm.user.bankaccount.id : '';
        vm.user.bankaccount.extra_info = !!vm.user.bankaccount.extra_info ? vm.user.bankaccount.extra_info : '';
        UsersService.saveBankAccount(vm.user.bankaccount).then(_success, _error);

        function _success(response) {
            if (response.data.data.response === 'success') {
                toaster.pop("success", "User updated", "Your bank account has been saved.", 1000);
                if (!vm.user.bankaccount.id) {
                    setTimeout(function () {
                        location.reload();
                    }, 1000);
                } else {
                    vm.savingData = false;
                }
            }
        }

        function _error(err) {
            console.log(err);
        }
    }

    function updatePassword() {
        vm.savingData = true;
        UsersService.updatePassword(vm.user.password).then(_success, _error);

        function _success(response) {
            if (response.data.data.response === 'success') {
                toaster.pop("success", "User updated", "Your password has been updated successfully.", 1000);
            } else {
                toaster.pop("warning", "Error", "Your password can't be the current password.", 1000);
            }
            vm.savingData = false;
            setTimeout(function () {
                location.reload();
            }, 1000);
        }

        function _error(err) {
            console.log(err);
        }
    }

    function checkPassword() {
        if (!vm.user.password || !vm.user.confirm_password)
            return;
        if (vm.user.password !== vm.user.confirm_password) {
            vm.passwordError = true;
        } else {
            vm.passwordError = false;
        }
    }

    function renderHtml(html_code) {
        return $sce.trustAsHtml(html_code);
    }

    function changePaymentMethod() {
        vm.paymentOption = '';
        vm.resetPayment = true;
    }

    $rootScope.$on('first_profile_setting', function () {
        vm.openModal();
    });

    init();
}