'use strict';

module.exports = NotificationController;

NotificationController.$inject = ['NotificationService', 'UsersService', '$uibModal', '$scope', 'toaster', '$state'];

function NotificationController(NotificationService, UsersService, $uibModal, $scope, toaster, $state) {

    /************************************************
     * VARIABLES
     ************************************************/
    var vm = this;
    vm.messages = [];
    vm.user = null;
    vm.messagesState = "1";
    vm.inboxMessages = [];
    vm.archivedMessages = [];
    vm.isCollapsed = true;

    /************************************************
     * METHODS
     ************************************************/
    vm.getNotification = getNotifications;
    vm.viewNotification = viewNotification;
    vm.archiveMessage = archiveMessage;
    vm.unarchiveMessage = unarchiveMessage;

    function init() {
        getUserInfo();
        getNotifications();
    }

    /**
     * @name getNotification
     * @description gets the notifications
     */
    function getNotifications() {
        vm.inboxMessages = [];
        vm.archivedMessages = [];
        vm.unreadNotificationCount = 0;
        NotificationService.getNotificationAll().then(function (response) {
            if (!!response.data.data.notifications.length) {
                var notificationList = response.data.data.notifications;
                vm.unreadNotificationCount = 0;
                notificationList.forEach(function (notification) {
                    notification.date = moment(notification.date, 'DD-MM-YYYY').format('LL');
                    if (notification.status === '0' || notification.status === '1')
                        vm.inboxMessages.push(notification);
                    else
                        vm.archivedMessages.push(notification);
                    
                    if (notification.status == '0') vm.unreadNotificationCount++;
                });
            }
        });
    }

    function viewNotification(notification) {
        NotificationService.updateNotification(notification.id, 1).then(function () {
            $state.go("notification_detail", {
                id: notification.id
            });
        });
    }

    /**
     * @name archiveMessage
     * @description change the state of a message to archived
     * @param _notification
     */
    function archiveMessage(_notification) {
        NotificationService.updateNotification(_notification.id, 2).then(function () {
            getNotification();
        });
    }

    /**
     * @name unarchiveMessage
     * @description change the state of a message to unarchived
     * @param _notification
     */
    function unarchiveMessage(_notification) {
        NotificationService.updateNotification(_notification.id, 1).then(function () {
            getNotification();
        });
    }

    /**
     * @name getUserInfo
     * @description Gets user info
     */
    function getUserInfo() {
        UsersService.getUserInfo().then(_setUserInfo);

        /**
         * @name _setUserInfo
         * @description gets the user info
         * @param response
         * @private
         */
        function _setUserInfo(response) {
            vm.user = response.data.data.user;
        }
    }

    init();

}