/**
 * Created by katat on 10/6/2017.
 */
module.exports = NotificationService;

NotificationService.$inject = ['$rootScope', '$http', '$log', '$q', 'StorageService'];

function NotificationService($rootScope, $http, $log, $q, StorageService) {

    _.extend(this, {
        saveNotification: saveNotification,
        getNotificationAll: getNotificationAll,
        getNotification: getNotification,
        getNotificationComments: getNotificationComments,
        saveNotificationComments: saveNotificationComments,
        updateNotification: updateNotification
    });


    function saveNotification(notification) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'message': notification.message,
            'host': notification.host,
            'id_event': notification.id_event
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Notification/save/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function getNotificationAll() {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({});
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Notification/getall/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function getNotification(id) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            id: id
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Notification/get/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function getNotificationComments(id) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            id: id
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Notification/getcomments/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function saveNotificationComments(comments) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'id_notification': comments.id,
            'comment': comments.comment
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Notification/savecomments/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function updateNotification(id, status) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'id': id,
            'status': status
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Notification/updatenotification/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }
}