'use strict';

module.exports = HowItWorksController;

HowItWorksController.$inject = ['ngMeta'];

function HowItWorksController(ngMeta) {
    /************************************************
     * VARIABLES
     ************************************************/
    var vm = this;
    vm.videoURL = '';
    vm.showButton = true;
    vm.playVid = playVid;
    vm.playButt = playButt;

    function playButt() {
        var pB = document.getElementsByTagName('video')[0];
        if (pB.paused) {
            pB.play();
            vm.showButton = false;
        } else {
            pB.pause();
            vm.showButton = true;
        }
    }

    function playVid() {
        var pB = document.getElementsByTagName('video')[0];
        vm.showButton = !pB.paused;
    }

    function init() {
        var subdomain = window.location.hostname.split('.')[0];
        //Gets language from subdomain
        var lang = (subdomain === 'www' || subdomain === 'localhost' || subdomain === 'test') ? 'en' : subdomain;
        var myVideo = document.getElementsByTagName('video')[0];
        var vidURL = baseURL + 'assets/video/' + lang + '.mp4';
        myVideo.src = vidURL;
        myVideo.autoplay = false;
        setTimeout(function () {
            myVideo.load();
        }, 500);


        // Meta Tag setting, title and description
        ngMeta.setTitle('How Foodiiz Works? Be a Guest or Host - Foodiiz Community');
        ngMeta.setTag('description', 'Explore hundreds of fantastic Foodiiz culinary experiences around the world. Foodiiz help you step by step to do your own dinner, lunch, breakfast, or specific event. Be a guest or welcome people like a chef at your table. Get in touch with us today to find out more.');
    }

    init();
}