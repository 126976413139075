'use strict';
module.exports = routerConfig;

routerConfig.$inject = ['$locationProvider', '$stateProvider', '$urlRouterProvider', 'ngMetaProvider'];

function routerConfig($locationProvider, $stateProvider, $urlRouterProvider, ngMetaProvider) {
    var myData = {
        meta: {
            'title': 'Foodiiz | A new way to socialise',
            'description': 'FOOD EXPERIENCES WITH LOCALS AROUND THE WORLD',
            'autor': 'Foodiiz'
        }
    };
    $stateProvider
        .state('home', {
            url: '/',
            templateUrl: 'assets/js/app/home/home.html',
            controller: 'HomeController',
            controllerAs: 'home',
            authenticate: false,
            whiteLogo: true,
            data: myData,
            meta: {
                disableUpdate: true
            }
        })
        .state('giftcard', {
            url: '/giftcard',
            templateUrl: 'assets/js/app/giftcard/giftcard.html',
            controller: 'GiftCardController',
            controllerAs: 'giftcard',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('search_hosts', {
            url: '/search/:city/:lat/:lng/:distance/:date/:cat',
            params: {
                city: {value: null, squash: true},
                lat: {value: null, squash: true},
                lng: {value: null, squash: true},
                distance: {value: null, squash: true},
                date: {value: null, squash: true},
                cat: {value: null, squash: true}
            },
            templateUrl: 'assets/js/app/search/search.html',
            controller: 'SearchHostsController',
            controllerAs: 'search',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('view_event', {
            url: '/event/:event/:date',
            params: {
                event: {value: null, squash: true},
                date: {value: null, squash: true}
            },
            templateUrl: 'assets/js/app/events/view_event.html',
            controller: 'ViewEventController',
            controllerAs: 'event',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('view_itinerary', {
            url: '/itinerary/:token',
            params: {
                token: {value: null, squash: true}
            },
            templateUrl: 'assets/js/app/events/event_itinerary.html',
            controller: 'ViewItineraryController',
            controllerAs: 'event',
            authenticate: true,
            whiteLogo: false,
            data: myData
        })
        .state('howitworks', {
            url: '/how-it-works',
            templateUrl: 'assets/js/app/how-it-works/how-it-works.html',
            controller: 'HowItWorksController',
            controllerAs: 'how',
            authenticate: false,
            whiteLogo: false,
            data: myData,
            meta: {
                disableUpdate: true
            }
        })
        .state('socialconnections', {
            url: '/social-connections',
            templateUrl: 'assets/js/app/social-connections/social-connections.html',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('whyhost', {
            url: '/why-host',
            templateUrl: 'assets/js/app/why-host/why-host.html',
            controller: 'WhyHostController',
            authenticate: false,
            whiteLogo: false,
            data: myData,
            meta: {
                disableUpdate: true
            }
        })
        .state('hostcancellationpolicy', {
            url: '/host-cancellation-policy',
            templateUrl: 'assets/js/app/host-cancellation-policy/host-cancellation-policy.html',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('faq', {
            url: '/faq',
            templateUrl: 'assets/js/app/faq/faq.html',
            controller: 'FAQController',
            controllerAs: 'faq',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('responsiblehosting', {
            url: '/responsible-hosting',
            templateUrl: 'assets/js/app/responsible-hosting/responsible-hosting.html',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('terms', {
            url: '/terms',
            templateUrl: 'assets/js/app/terms/terms.html',
            authenticate: false,
            whiteLogo: false,
        })
        .state('policies', {
            url: '/policies',
            templateUrl: 'assets/js/app/policies/policies.html',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('aboutus', {
            url: '/about-us',
            templateUrl: 'assets/js/app/about-us/about-us.html',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('press', {
            url: '/press',
            templateUrl: 'assets/js/app/press/press.html',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('user_dashboard', {
            url: '/user/dashboard',
            templateUrl: 'assets/js/app/dashboard/dashboard.html',
            controller: 'DashboardController',
            controllerAs: 'dashboard',
            authenticate: true,
            whiteLogo: false,
            data: myData
        })
        .state('your_listings', {
            url: '/user/foodexperience',
            templateUrl: 'assets/js/app/hosts/your-listings.html',
            controller: 'HostsController',
            controllerAs: 'hosts',
            authenticate: true,
            whiteLogo: false,
            data: myData
        })
        .state('events_dates', {
            url: '/user/eventsdates/:id/:date/:token/:action',
            params: {
                id: {value: null, squash: true},
                date: {value: null, squash: true},
                token: {value: null, squash: true},
                action: {value: null, squash: true}
            },
            templateUrl: 'assets/js/app/hosts/eventsDates.html',
            controller: 'EventDatesController',
            controllerAs: 'events',
            authenticate: true,
            whiteLogo: false,
            data: myData
        })
        .state('hosts_new', {
            url: '/user/hostsnew/:data',
            templateUrl: 'assets/js/app/hosts/hosts-new.html',
            controller: 'HostsController',
            controllerAs: 'hosts',
            authenticate: true,
            whiteLogo: false,
            data: myData
        })
        .state('contact', {
            url: '/contact',
            templateUrl: 'assets/js/app/contact/contact.html',
            controller: 'ContactController',
            controllerAs: 'contact',
            meta: {
                disableUpdate: true
            }
        })
        .state('edit_user', {
            url: '/user/edit',
            templateUrl: 'assets/js/app/users/edit-user.html',
            controller: 'UserController',
            controllerAs: 'users',
            authenticate: true,
            whiteLogo: false,
            data: myData
        })
        .state('settings', {
            url: '/user/settings',
            templateUrl: 'assets/js/app/users/user-settings.html',
            controller: 'UserController',
            controllerAs: 'users',
            authenticate: true,
            whiteLogo: false,
            data: myData
        })
        .state('view_user', {
            url: '/users/view/:id',
            params: {id: null},
            templateUrl: 'assets/js/app/users/view-user.html',
            controller: 'UserController',
            controllerAs: 'users',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('notification', {
            url: '/user/notification',
            templateUrl: 'assets/js/app/notification/notification.html',
            controller: 'NotificationController',
            controllerAs: 'notification',
            authenticate: true,
            whiteLogo: false,
            data: myData
        })
        .state('notification_detail', {
            url: '/user/notification/:id',
            templateUrl: 'assets/js/app/notification/notification_detail.html',
            controller: 'NotificationDetailController',
            controllerAs: 'notification',
            authenticate: true,
            whiteLogo: false,
            data: myData
        })
        .state('register_email', {
            url: '/confirm-your-email/:token',
            params: {token: {value: null, squash: true}},
            templateUrl: 'assets/js/app/users/confirm-your-email.html',
            controller: 'ConfirmationController',
            controllerAs: 'confirmation',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('recovery_password', {
            url: '/reset-password/:token',
            params: {token: {value: null, squash: true}},
            templateUrl: 'assets/js/app/users/reset-password.html',
            controller: 'PasswordController',
            controllerAs: 'password',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('welcome', {
            url: '/welcome/:ref',
            params: {ref: {value: null, squash: true}},
            templateUrl: 'assets/js/app/welcome/welcome.html',
            controller: 'WelcomeController',
            controllerAs: 'welcome',
            authenticate: false,
            whiteLogo: true,
            data: myData
        })
        .state('register', {
            url: '/register',
            templateUrl: 'assets/js/app/register/register.html',
            controller: 'RegisterController',
            controllerAs: 'register',
            authenticate: false,
            whiteLogo: true,
            data: myData
        })
        .state('blog', {
            url: '/blog',
            params: {id: null},
            templateUrl: 'assets/js/app/blog/blog.html',
            controller: 'BlogController',
            controllerAs: 'blog',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('landing', {
            url: '/landing',
            params: {id: null},
            templateUrl: 'assets/js/app/landing/landing.html',
            controller: 'LandingController',
            controllerAs: 'landing',
            authenticate: false,
            whiteLogo: false,
            data: myData
        })
        .state('thank_you', {
            url: '/thank-you',
            params: {id: null},
            templateUrl: 'assets/js/app/thank-you-page/thank-you.html',
            controller: 'ThankYouController',
            controllerAs: 'thank-you',
            authenticate: false,
            whiteLogo: false,
            data: myData
        });
    $locationProvider.html5Mode(true);
    $urlRouterProvider.otherwise('/');
    ngMetaProvider.useTitleSuffix(true);
}