'use strict';

module.exports = WhyHostController;

WhyHostController.$inject = ['ngMeta'];

function WhyHostController(ngMeta) {
    var vm = this;
    vm.init = init;

    /**
     * @name init
     * @description to set the title and description
     */
    function init() {
        ngMeta.setTitle('Hosting With Foodiiz, Premium Host Programs, Earn Money');
        ngMeta.setTag('description', 'Choose our premium hosting programs and earn a nice extra income by hosting people for a nice and delicious food. Foodiiz takes 0% commissions from the hosts, we want you to have full benefit of the experience.');
    }

    init();
}