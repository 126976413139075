'use strict';

module.exports = NotificationDetailController;

NotificationDetailController.$inject = ['NotificationService', 'UsersService', '$stateParams', 'toaster'];

function NotificationDetailController(NotificationService, UsersService, $stateParams, toaster) {

    /************************************************
     * VARIABLES
     ************************************************/
    var vm = this;
    vm.comments = [];
    vm.host = {};
    vm.loading = false;
    vm.isCollapsed = true;
    vm.message = "";
    vm.displayError = false;

    /************************************************
     * METHODS
     ************************************************/
    vm.send = send;

    function init() {
        vm.loading = true;
        var id = $stateParams.id;
        NotificationService.getNotification(id).then(_setMessageData);
        function _setMessageData(result) {
            if (result.data.data.can_read) {
                var idHost = result.data.data.notifications[0].host;
                UsersService.getUserInfobyId(idHost).then(_setHostData);
                getMessages();
            } else {
                vm.displayError = true;
            }
        }
        function _setHostData(result) {
            vm.host = result.data.data.user;
        }

        vm.loading = false;
    }

    function getMessages() {
        var id = $stateParams.id;
        NotificationService.getNotificationComments(id).then(_setMessages);
        function _setMessages(result) {
            if (!!result.data.data.comments.length) {
                result.data.data.comments.forEach(function (comment) {
                    comment.date = moment.utc(comment.date).local().format('DD-MM-YYYY HH:mm');
                });
                vm.comments = result.data.data.comments;
            }
        }
    }

    function send() {
        if (vm.message === '')
            return;
        var id = $stateParams.id;
        var message = {'id': id, 'comment': vm.message};
        NotificationService.saveNotificationComments(message).then(
            function() {
                toaster.pop("success", "Message sending", "Your message was sent.", 3000);
                getMessages(); 
            },
            function (err) {
                toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
            });
        vm.message = "";
    }

    init();
}