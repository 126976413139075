'use strict';

module.exports = LandingController;

LandingController.$inject = ['$scope', '$rootScope', '$uibModal', 'UsersService', 'toaster', '$window', '$element', 'StorageService', '$timeout', 'ngMeta', '$state'];

function LandingController($scope, $rootScope, $uibModal, UsersService, toaster, $window, $element, StorageService, $timeout, ngMeta, $state) {
    /************************************************
     * VARIABLES
     ************************************************/
    var vm = this;
    vm.videoURL = '';
    vm.showButton = true;
    vm.loading = false;
    vm.user = {};
    vm.playVid = playVid;
    vm.playButt = playButt;
    vm.loginFacebook = loginFacebook;
    vm.startApp = startApp;
    vm.processForm = processForm;
    vm.login = login;

    function playButt() {
        var pB = document.getElementsByTagName('video')[0];
        if (pB.paused) {
            pB.play();
            vm.showButton = false;
        } else {
            pB.pause();
            vm.showButton = true;
        }
    }

    function playVid() {
        var pB = document.getElementsByTagName('video')[0];
        vm.showButton = !pB.paused;
    }

    function getElementsByClassName(classname, node)  {
        if(!node) node = document.getElementsByTagName("body")[0];
        var a = [];
        var re = new RegExp('\\b' + classname + '\\b');
        var els = node.getElementsByTagName("*");
        for(var i=0,j=els.length; i<j; i++)
            if(re.test(els[i].className))a.push(els[i]);
        return a;
    }

    function init() {
        var elements = getElementsByClassName('navbar-static-top');
        for(var i = 0; i < elements.length; i++ ){
            elements[i].style.display = "none";
        }
        document.getElementById('main_content').style.paddingTop = 0;
        var subdomain = window.location.hostname.split('.')[0];
        //Gets language from subdomain
        var lang = (subdomain === 'www' || subdomain === 'localhost' || subdomain === 'test') ? 'en' : subdomain;
        var myVideo = document.getElementsByTagName('video')[0];
        var vidURL = baseURL + 'assets/video/' + lang + '.mp4';
        myVideo.src = vidURL;
        myVideo.autoplay = false;
        setTimeout(function () {
            myVideo.load();

        }, 500);
        // Meta Tag setting, title and description
        ngMeta.setTitle('How Foodiiz Works? Be a Guest or Host - Foodiiz Community');
        ngMeta.setTag('description', 'Explore hundreds of fantastic Foodiiz culinary experiences around the world. Foodiiz help you step by step to do your own dinner, lunch, breakfast, or specific event. Be a guest or welcome people like a chef at your table. Get in touch with us today to find out more.');
    }

    function startApp() {
        if (gapi.auth2 === undefined) {
            $timeout(function () {
                startApp();
            }, 5000);
        } else {
            gapi.load('auth2', function () {
                // Retrieve the singleton for the GoogleAuth library and set up the client.
                //597088906315-qprlhb07k3o3eg6lohn9mqt7mvqc80ns.apps.googleusercontent.com
                vm.auth2 = gapi.auth2.init({
                    client_id: '900090985797-754v3malldbfteovldb3iih6f72oukut.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    // Request scopes in addition to 'profile' and 'email'
                    //scope: 'additional_scope'
                });
                attachSignin(document.getElementById('customBtn'));
            });
        }
    }

    function attachSignin(element) {
        vm.auth2.attachClickHandler(element, {},
            function (googleUser) {
                vm.loading = true;
                var profile = googleUser.getBasicProfile();
                var token = '';
                UsersService.signUpGoogle(profile).then(
                    function (response) {
                        //debugger;
                        if (response.data.data.response === 'success') {
                            toaster.pop("success", "Account created", "Your account was created successfully, please check your email.", 4000);
                            token = response.data.data.token;
                            StorageService.setItem('token', token);
                            setTimeout(function () {
                                $state.go("thank_you");
                            }, 500);
                        } else if (response.data.data.response === 'login') {
                            token = response.data.data.token;
                            StorageService.setItem('token', token);
                            setTimeout(function () {
                                window.location.href = baseURL;
                            }, 500);
                        }
                    },
                    function (err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                );
            }, function (error) {
                console.log(JSON.stringify(error, undefined, 2));
            });
    }

    function loginFacebook() {
        //FB.logout(function(){document.location.reload();});
        FB.login(function (response) {
            if (response.authResponse) {
                getUserInfo();
            } else {
                console.log('User canceled login or did not fully authorize.');
            }
        }, {scope: 'email,user_photos,user_videos'});
    }

    function getUserInfo() {
        $scope.loading = true;
        var token = '';
        FB.api('/me',
            {fields: "email,first_name,last_name,gender"},
            function (response) {
                response.cover_url = "http://graph.facebook.com/" + response.id + "/picture?type=normal";
                UsersService.signUpFacebook(response).then(
                    function (response) {
                        //debugger;
                        if (response.data.data.response === 'success') {
                            toaster.pop("success", "Account created", "Your account was created successfully, please check your email.", 4000);
                            token = response.data.data.token;
                            StorageService.setItem('token', token);
                            $state.go("thank_you");
                        } else if (response.data.data.response === 'login') {
                            token = response.data.data.token;
                            StorageService.setItem('token', token);
                            setTimeout(function () {
                                window.location.href = baseURL;
                            }, 500);
                        }
                    },
                    function (err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                );
            });
    }

    function processForm() {
        vm.loading = true;
        vm.user.newsLetter = 1;


        var subdomain = window.location.hostname.split('.')[0]; // nabi new
        //Gets language from subdomain
        vm.user.lang = (subdomain === 'www' || subdomain === 'localhost' || subdomain === 'test') ? 'en' : subdomain; // nabi new
        
        UsersService.signUp(vm.user).then(
            function (response) {
                if (response.data.data.response === 'success') {
                    toaster.pop("success", "Account created", "Your account was created successfully, please check your email.", 1000);
                    setTimeout(function () {
                        $state.go("thank_you");
                    }, 1000);
                } else if (response.data.data.response === 'login') {
                    setTimeout(function () {
                        window.location.href = baseURL;
                    }, 1000);
                }
            },
            function (err) {
                toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
            }
        );
    }

    function login(index) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'assets/js/app/modal/login.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 1,
            resolve: {
                data: function () {
                    return $scope.data;
                }.bind(this)
            }
        });

        modalInstance.result.then(function (data) {
            if (!!data.recoveryEmail) {
                UsersService.sendResetToken(data.recoveryEmail).then(
                    function (response) {
                        toaster.pop("success", "Password Reset", "Instructions were sent successfully, please check your email.", 4000);
                    },
                    function (err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                );
            } else {
                UsersService.isVerified(data).then(
                    function (response) {
                        if (response.data.data.response === 'confirmed') {
                            var loginMethod = null;
                            if (!!data.cover_url) loginMethod = UsersService.signUpFacebook;
                            else if (!!data['Paa']) loginMethod = UsersService.signUpGoogle;
                            else loginMethod = UsersService.login;
                            loginMethod(data).then(
                                function (response) {
                                    if (response.data.data.response === 'success' || response.data.data.response === 'login') {
                                        //var user = response.data.data.user;
                                        var token = response.data.data.token;
                                        //StorageService.setItem('user', JSON.stringify(user));
                                        StorageService.setItem('token', token);
                                        setTimeout(function () {
                                            window.location.href = baseURL;
                                        }, 500);
                                    } else {
                                        toaster.pop("warning", "Incorrect info", "Please check you email and password and try again.", 4000);
                                    }
                                },
                                function (err) {
                                    toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                                }
                            );
                        } else if (response.data.data.response === 'no_confirmed') {
                            toaster.pop('error', "Error", "Your email did not verified, please try email verification.", 4000);
                        } else {
                            toaster.pop('error', "Error", "Your email did not registered, please try sign up.", 4000);
                        }

                    }
                );
            }
        }).catch(function (res) {
            modalInstance.close();
        });
    }

    init();
}