module.exports = HostsService;

HostsService.$inject = ['$cookies'];

function HostsService($cookies) {

    _.extend(this, {
        setItem: setItem,
        getItem: getItem,
        deleteItem: deleteItem
    });

    /**
     * @name setItem
     * @param name
     * @param item
     */
    function setItem(name, item) {
        var domain = (window.location.href.indexOf("localhost") > -1) ? '.app.localhost' : 'foodiiz.com';
        var options = domain === '.app.localhost' ? {path: '/'} : {domain: domain};
        $cookies.put(name, item, options);
    }

    /**
     * name getItem
     * @param name
     * @returns {string}
     */
    function getItem(name) {
        return $cookies.get(name);
    }

    /**
     * name deleteItem
     * @param name
     */
    function deleteItem(name) {
        var domain = (window.location.href.indexOf("localhost") > -1) ? '.app.localhost' : 'foodiiz.com';
        var options = domain === '.app.localhost' ? {path: '/'} : {domain: domain};
        $cookies.remove(name, options);

    }
}