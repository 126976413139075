module.exports = UsersService;

UsersService.$inject = ['$rootScope', '$http', '$log', '$q', 'StorageService'];

function UsersService($rootScope, $http, $log, $q, StorageService) {

    $rootScope.currentUser = null;

    _.extend(this, {
        signUp: signUp,
        isVerified: isVerified,
        signUpFacebook: signUpFacebook,
        signUpGoogle: signUpGoogle,
        login: login,
        sendResetToken: sendResetToken,
        getUserInfo: getUserInfo,
        saveUser: saveUser,
        confirmEmail: confirmEmail,
        validateResetToken: validateResetToken,
        resetPassword: resetPassword,
        saveImage: saveImage,
        getUserInfobyId: getUserInfobyId,
        showUserbyId: showUserbyId,
        getCountriesPayout: getCountriesPayout,
        saveStripeAccount: saveStripeAccount,
        savePayPalAccount: savePayPalAccount,
        saveBankAccount: saveBankAccount,
        updateCurrency: updateCurrency,
        updateLanguage: updateLanguage,
        updatePassword: updatePassword,
        getStripePermissions: getStripePermissions,
        becomePremium: becomePremium,
        deleteUser: deleteUser,
        newsletterSignup: newsletterSignup
    });

    /**
     * @name signUp
     * @description attempts to register the user
     * @param {Object} user
     */
    function signUp(user) {
        var deferred = $q.defer();
        var newsLetter = !!user.newsLetter ? 1 : 0;
        var organizeFoodEvent = !!user.organizeFoodEvent ? 1 : 0;
        var premium = !!user.premium ? 1: 0;
        $params = $.param({
            'first_name': user.first_name,
            'last_name': user.last_name,
            'email': user.email,
            'password': user.password,
            'news': newsLetter,
            'organize': organizeFoodEvent, 
            'premium': premium,
            'lang': user.lang //nabi new
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/register/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    /**
     * @name googleSingup
     * @description registers a new user
     * @param user
     * @returns {*}
     */
    function signUpGoogle(user) {
        var deferred = $q.defer();
        $params = $.param({
            'first_name': user['ofa'],
            'last_name': user['wea'],
            'email': user['U3'],
            'cover_url': user['Paa']
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/registerGoogle/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    /**
     * @name signUpFacebook
     * @description registers a new user
     * @param {type} user
     * @returns {.$q@call;defer.promise}
     */
    function signUpFacebook(user) {
        var deferred = $q.defer();
        $params = $.param({
            'first_name': user.first_name,
            'last_name': user.last_name,
            'email': user.email,
            'gender': user.gender,
            'cover_url': user.cover_url
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/registerFacebook/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    /**
     * @name isVerified
     * @description checks if the email exists
     * @param user
     * @returns {*}
     */
    function isVerified(user) {
        var deferred = $q.defer();
        var email = !!user.email ? user.email : user['U3'];
        $params = $.param({
            'email': email,
            'password': user.password
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/isVerified/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;

    }

    /**
     * @name login
     * @description attempts to login the user
     * @param {Object} user
     */
    function login(user) {
        var deferred = $q.defer();
        $params = $.param({
            'email': user.email,
            'password': user.password
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/login/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    /**
     * @name getUserinfo
     * @description gets the user info
     * @returns {deferred.promise|{then, catch, finally}}
     */
    function getUserInfo() {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/getUserInfo/' + token,
            method: 'GET'
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        //var user = JSON.parse(StorageService.getItem('user'));
        //deferred.resolve({data: user});
        return deferred.promise;
    }

    /**
     * @name getUserinfo by id
     * @description gets the user info by id
     * @returns {deferred.promise|{then, catch, finally}}
     */
    function getUserInfobyId(id) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'id': id
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/getUserInfoId/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    /**
     * @name getUserinfo by id
     * @description gets the user info by id
     * @returns {deferred.promise|{then, catch, finally}}
     */
    function showUserbyId(id) {
        var deferred = $q.defer();
        $params = $.param({
            'id': id
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/showUserById/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    /**
     * @name saveuser
     * @description Saves the user
     * @param user
     * @returns {deferred.promise|{then, catch, finally}}
     */
    function saveUser(user) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'first_name': user.first_name,
            'last_name': user.last_name,
            'username': user.username,
            'address': user.address,
            'birthdate': moment(user.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            'currency': user.currency,
            'description': user.description,
            'genre': user.genre,
            'language': user.language,
            'phone_number': user.phone_number,
            'country_code': user.country_code,
            'preferences': user.preferences,
            'school': user.school,
            'time_zone': user.time_zone,
            'work': user.work,
            'languages': user.languages,
            'news': user.news
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/save/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function confirmEmail(token) {
        var deferred = $q.defer();
        $params = $.param({
            'token': token
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/confirmEmail/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function validateResetToken(token) {
        var deferred = $q.defer();
        $params = $.param({
            'token': token
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/validateResetToken/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function saveImage(image) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $http({
            method: 'POST',
            url: baseURL + 'Users/saveImage/' + token,
            secureuri: false,
            headers: {'Content-Type': 'multipart/form-data'},
            dataType: 'json',
            data: {
                file: image
            },
            transformRequest: function (data, headersGetter) {
                var formData = new FormData();
                angular.forEach(data, function (value, key) {
                    formData.append(key, value);
                });
                var headers = headersGetter();
                delete headers['Content-Type'];
                return formData;
            }
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function sendResetToken(email) {
        var deferred = $q.defer();
        $params = $.param({
            'email': email
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/sendResetToken/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function resetPassword(email, password) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'email': email,
            'password': password
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/resetPassword/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function getCountriesPayout() {
        var deferred = $q.defer();
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Stripe/Payment/getCountries',
            method: 'GET'
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function saveStripeAccount(account, country) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'id': account.id,
            'stripe_account': account.account_id
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/saveStripeAccount/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function getStripePermissions(code) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'code': code
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Stripe/Payment/getStripePermissions/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function becomePremium() {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/becomePremium/' + token,
            method: 'POST'
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function savePayPalAccount(account) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'id': account.id,
            'paypal_email': account.paypal_email
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/savePaypalAccount/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function saveBankAccount(account) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'id': account.id,
            'currency': account.currency,
            'username': account.user_name,
            'bankname': account.bank_name,
            'banknumber': account.bank_number,
            'bankswift': account.bank_swift,
            'extrainfo': account.extra_info
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/saveBankAccount/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function updateCurrency(_currency) {

        /*$params = $.param({
            'currency': _currency
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/updateCurrency/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });*/
        var deferred = $q.defer();
        var user = JSON.parse(StorageService.getItem('globalUser'));
        user.currency = _currency;
        StorageService.setItem('globalUser', JSON.stringify(user));
        deferred.resolve({'response': 'success'});
        return deferred.promise;
    }

    function updateLanguage(_language) {
        var deferred = $q.defer();
        $params = $.param({
            'language': _language
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/updateLanguage/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function updatePassword(password) {
        var deferred = $q.defer();
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $params = $.param({
            'password': password
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/updatePassword/' + token,
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }

    function deleteUser(user) {
        var deferred = $q.defer();
        $params = $.param({
            'user_id': user.id
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/deleteUser/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }


    /**
     * @name newsletterSignup
     * @description Newsletter Signup(not a typical method to be within UserService, but just to avoid to create one file)
     * @param email
     * @returns {*}
     */
    function newsletterSignup(email) {
        var deferred = $q.defer();
        $params = $.param({
            'email': email
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/newsletterSignup/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }
}