module.exports = AuthenticationService;

AuthenticationService.$inject = ['$http', '$rootScope', 'StorageService', '$q'];

function AuthenticationService($http, $rootScope, StorageService, $q) {

    $rootScope.currentUser = null;

    _.extend(this, {
        RoutingError: RoutingError,
        isAuthenticated: isAuthenticated
    });

    /**
     * @name RoutingError
     * @description Returns error when a route is not resolved
     * @param msg
     * @param state
     * @param params
     * @returns {Error}
     * @constructor
     */
    function RoutingError(msg, state, params) {
        var err = new Error(msg);
        err.state = state;
        err.params = params;
        return err;
    }

    /**
     * @name isAuthenticated
     * @description Check if the user has a session
     */
    function isAuthenticated() {
        var deferred = $q.defer();
        /*var user = StorageService.getItem('user');
        var globalUser = StorageService.getItem('globalUser');
        if(!globalUser) {
            var globalData = {
                'currency': 'EUR',
                'language': 'en'
            };
            StorageService.setItem('globalUser', JSON.stringify(globalData));
            globalUser = globalData;
        }
        var session = false;
        if(!!user) {
            user = JSON.parse(user);
            session = true;
        }
        var data = {
            'user': user,
            'globalUser': globalUser,
            'session': session
        };
        deferred.resolve({data: data});
        $rootScope.currentUser = null;
        return deferred.promise;*/
        var token = StorageService.getItem('token');
        if (!token) token = "";
        $rootScope.currentUser = null;
        $params = $.param({
            'token': token
        });
        return $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Users/checkSession/',
            method: 'POST',
            data: $params
        });
        
    }
}
