module.exports = BlogService;

BlogService.$inject = ['$http', '$log', '$q'];

function BlogService($http, $log, $q) {
    _.extend(this, {
        getPosts: getPosts
    });

    /**
     * @name signUp
     * @description attempts to register the user
     * @param {Object} user
     */
    function getPosts(initDate, endDate) {
        var deferred = $q.defer();
        $params = $.param({
            'start': initDate,
            'end': endDate
        });
        $http({
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: baseURL + 'Blog/getPosts/',
            method: 'POST',
            data: $params
        }).then(function (data) {
            deferred.resolve({data: data});
        }, function (msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    }
}