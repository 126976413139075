module.exports = setMobileSticky;

setMobileSticky.$inject = ['$window', '$timeout'];

function setMobileSticky($window, $timeout) {
    return function (scope, elm, attr) {
      angular.element($window).bind('scroll', function () {
        if (this.pageYOffset >= 300) {
            scope.toSticky = true;
        } else {
            scope.toSticky = false;
        }
        scope.$apply();
      });
    };
  }