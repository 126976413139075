'use strict';

module.exports = HomeController;

HomeController.$inject = ['$state', '$http', 'toaster', 'HostsService', '$rootScope', 'currency_translateFilter', '$sce', '$translate', '$timeout', 'StorageService', 'UsersService', 'ngMeta'];

function HomeController($state, $http, toaster, HostsService, $rootScope, currency_translateFilter, $sce, $translate, $timeout, StorageService, UsersService, ngMeta) {

    /************************************************
     * VARIABLES
     ************************************************/
    var vm = this;
    var startDate = '';
    //Translations
    vm.country_SE = '';
    vm.country_DK = '';
    vm.country_FR = '';
    vm.country_DK = '';
    vm.country_IT = '';
    vm.country_CR = '';
    vm.country_MA = '';
    vm.country_IE = '';
    vm.country_TN = '';
    vm.country_NL = '';
    vm.isMobile = false;
    vm.premium_hosts = [];
    vm.isLoading = false;
    vm.location = "";
    vm.active = 0;
    vm.currIndex = 0;
    vm.noWrapSlides = false;
    vm.myInterval = 5000;
    vm.myTransition = false;
    vm.selectedLanguage = null;
    vm.currency = '';
    vm.slides = [
        {
            image: baseURL + 'assets/images/more-cities/las-vegas.jpg',
            title: "Las Vegas"
        },
        {
            image: baseURL + 'assets/images/more-cities/moscou.jpg',
            title: "Moscou"
        },
        {
            image: baseURL + 'assets/images/more-cities/vancouver.jpg',
            title: "Vancouver"
        }
    ];
    vm.searchobject = {};
    vm.defaultUserImg = baseURL + "assets/images/search/default-user.jpg";

    vm.event_sold_text = '';
    vm.hosted_by_text = '';
    vm.view_event_free_text = '';
    vm.profile_reviews_text = '';
    vm.view_event_no_text = '';
    vm.displayCountries = [];
    vm.displayCities = [];
    vm.displayCategories = [];
    vm.translationReady = false;
    vm.active1 = 0;
    vm.actibe2 = 0;
    vm.active3 = 0;
    vm.translationLabels = [];
    vm.header_search_text_mobile = '';
    vm.header_search_text = '';

    /************************************************
     * METHODS
     ************************************************/
    vm.search = search;
    vm.getDisplayDate = getDisplayDate;
    vm.goToHost = goToHost;
    vm.getSlides = getSlides;
    vm.renderHtml = renderHtml;
    vm.showNext = showNext;
    vm.showPrev = showPrev;
    vm.getNumber = getNumber;
    vm.getCountrySlides = getCountrySlides;
    vm.getCitiesSlides = getCitiesSlides;
    vm.signNewsletter = signNewsletter;
    /**
     * @name showNext
     * @description calls the next slide
     * @returns {undefined}
     */
    function showNext(parent) {
        var element = angular.element('#' + parent + ' .left');
        if (element.length > 0 && element.scope && element.scope().next) {
            element.scope().next();
        }
    }

    /**
     * @name showPrev
     * @description calls the prev slide
     * @returns {undefined}
     */
    function showPrev(parent) {
        var element = angular.element('#' + parent + ' .right');
        if (element.length > 0 && element.scope && element.scope().prev) {
            element.scope().prev();
        }
    }

    function search() {
        if (!vm.searchobject.lat || !vm.searchobject.lng)
            return;
        var date = vm.date === startDate ? null : vm.date;
        $state.go("search_hosts", {
            city: vm.location,
            lat: vm.searchobject.lat,
            lng: vm.searchobject.lng,
            distance: '50',
            date: date
        });
    }

    /**
     * @name initGeolocation
     * @description Tries to get client location
     */
    function initGeolocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(_success, _fail);
        }

        /**
         * @name _success
         * @param position
         * @private
         */
        function _success(position) {
            $(document).trigger('init_map');
            $http({
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&sensor=true&key=AIzaSyCAnsJJZJ-AaVK7OBj15fiWgHwPA1jhBIM',
                method: 'GET'
            }).then(function (data) {
                vm.location = data.data.results[0].formatted_address;
                data.data.results[0].address_components.forEach(function (_component) {
                    _component.types.forEach(function (_type) {
                        if (_type === 'country') {
                            localStorage.setItem('country_name', _component.long_name);
                        }
                    });
                });
                vm.searchobject.lat = position.coords.latitude;
                vm.searchobject.lng = position.coords.longitude;
                translateFate();
                //set text to mobile and desktop device
                 if(vm.isMobile)
                    angular.element('#searchTextField').attr('placeholder',vm.header_search_text_mobile);
                else    
                    angular.element('#searchTextField').attr('placeholder',vm.header_search_text);
            }, function (msg) {
                console.log("Sorry, your browser does not support geolocation services: " + msg);
            });
        }

        function _fail() {
            console.log("Sorry, your browser does not support geolocation services.");
        }
    }

    function getReviewsAverage(_reviews) {
        var sum = 0;
        for (var i = 0; i < _reviews.length; i++) {
            sum += parseInt(_reviews[i].stars, 10); //don't forget to add the base
        }
        return Math.round(sum / _reviews.length);
    }

    /**
     * @name newFindClosest
     * @description Get the closest dates of a given date
     * @param {type} dates
     * @param {type} testDate
     * @returns {Object}
     */
    function newFindClosest(dates, testDate) {
        var before = [];
        var after = [];
        var arrDate = null;
        testDate = testDate.split('-');
        testDate = new Date(testDate[0], testDate[1] - 1, testDate[2]);

        dates.forEach(function (date, i) {
            var tar = date.event_date;
            tar = tar.split('-');
            arrDate = new Date(tar[0], tar[1] - 1, tar[2]);
            var diff = (arrDate - testDate) / (3600 * 24 * 1000);
            if (diff > 0) {
                before.push({diff: diff, index: i, date: date});
            } else {
                after.push({diff: diff, index: i, date: date});
            }
        });

        before.sort(function (a, b) {
            if (a.diff < b.diff) {
                return -1;
            }
            if (a.diff > b.diff) {
                return 1;
            }
            return 0;
        });

        after.sort(function (a, b) {
            if (a.diff > b.diff) {
                return -1;
            }
            if (a.diff < b.diff) {
                return 1;
            }
            return 0;
        });
        return {datesBefore: before, datesAfter: after};
    }

    /**
     * @name filterByDateAvailability
     * @description checks if the event is available for a given date
     * @param _events
     * @param date
     */
    function filterByDateAvailability(_events, date) {
        var currentDate = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        var filteredArray = [];
        _events.forEach(function (_event) {
            _event.displayClass = '';
            _event.dates.forEach(function (_date, i) {
                if (i === _event.dates.length - 1) {
                    var closestDates = newFindClosest(_event.dates, currentDate);
                    if (!!closestDates.datesBefore.length) {
                        _event.displayDate = closestDates.datesBefore[0].date.event_date;
                    } else if (!!closestDates.datesAfter.length) {
                        _event.displayDate = closestDates.datesAfter[0].date.event_date;
                    } else {
                        _event.displayDate = date;
                    }
                    filteredArray.push(_event);
                }
            });
        });
        return filteredArray;
    }

    function getPremiumHosts() {
        if(vm.isLoading) return;
        vm.isLoading = true;
        vm.premium_hosts = [];
        var currency = StorageService.getItem('currency');
        vm.currency = currency;
        HostsService.getPremiumHosts(currency).then(_setHosts);

        /**
         * @name _setHosts
         * @description gets premium hosts
         * @param {type} response
         * @returns {undefined}
         */
        function _setHosts(response) {
            var array = $.map(response.data.data.premium_hosts, function (value) {
                return [value];
            });
            vm.premium_hosts = filterByDateAvailability(array, vm.date);
            vm.premium_hosts.forEach(function (_event) {
                _event.experience = JSON.parse(_event.experience);
                _event.displayEvent = true;
                _event.reviews_qty = !!_event.reviews.length ? _event.reviews.length : 0;
                _event.reviews_average = !!_event.reviews.length ? getReviewsAverage(_event.reviews) : null;
                _event.soldout = _event.soldout === '1';
                _event.free = _event.free === '1';
            });
            angular.element('#events-results').on('click', '.host-card-result', getHostData);
            vm.isLoading = false;
        }
    }

    /**
     * @name getHostData
     * @description gets the host data and redirects the user to the event
     * @param {type} e
     * @returns {undefined}
     */
    function getHostData(e) {
        e.preventDefault();
        var target = $(e.currentTarget);
        var id = $(target).attr('id');
        var date = $(target).attr('date');
        var searchDate = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
        $state.go("view_event", {event: id, date: searchDate});
    }

    /**
     * @name goToHost
     * @description redirects the user to the event page
     * @param host
     */
    function goToHost(host) {
        var searchDate = moment(host.displayDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
        $state.go("view_event", {
            event: host.id_event,
            date: searchDate
        });
    }

    function renderHtml(html_code) {
        return $sce.trustAsHtml(html_code);
    }

    /**
     * @name getDisplayDate
     * @param {type} _date
     * @returns {unresolved}
     */
    function getDisplayDate(_date) {
        var date = moment(_date, 'YYYY-MM-DD');
        return moment(date).format('LL');
    }

    /**
     * @name getCountrySlides
     * @param $index
     * @returns {*}
     */
    function getCountrySlides($index) {
        var countries = '';
        if (vm.isMobile) {
            var first = vm.displayCountries[$index];
            countries += countryHTML(first);
        } else {
            var max = vm.displayCountries.length - 1;
            var first = vm.displayCountries[$index];
            var second, third, fourth;
            if ($index === max) {
                second = vm.displayCountries[0];
                third = vm.displayCountries[1];
                fourth = vm.displayCountries[2];
            } else if ($index === max - 1) {
                second = vm.displayCountries[max];
                third = vm.displayCountries[0];
                fourth = vm.displayCountries[1];
            } else if ($index === max - 2) {
                second = vm.displayCountries[$index + 1];
                third = vm.displayCountries[$index + 2];
                fourth = vm.displayCountries[0];
            } else {
                second = vm.displayCountries[$index + 1];
                third = vm.displayCountries[$index + 2];
                fourth = vm.displayCountries[$index + 3];
            }
            countries += countryHTML(first);
            countries += countryHTML(second);
            countries += countryHTML(third);
            countries += countryHTML(fourth);
        }

        return renderHtml(countries);
    }

    function countryHTML(country) {
        if (!country)
            return;
        var hostTemplate = '';
        //Init
        hostTemplate += '<div class="col-xs-12 col-sm-3 col-md-3 host-card-result single country">';
        //Host result
        hostTemplate += '<div class="host-result">';
        //Header
        hostTemplate += '<div class="host-card-header summary-card-cover" style="background-image: url(' + country.picture + ');">';
        //Link
        hostTemplate += '<a href="' + country.url + '">';
        //Header content
        hostTemplate += '<div class="host-sold-out"><span class="host-sold-text text-uppercase" style="color:' + country.text_color + ';">' + country.name + '</span></div>';
        //Close link
        hostTemplate += '</a>';
        //Close Header
        hostTemplate += '</div>';
        //Close Host Result
        hostTemplate += '</div>';
        //host-card-result
        hostTemplate += '</div>';
        return hostTemplate;
    }

    /**
     * @name getDisplayDate
     * @param {type} _date
     * @returns {unresolved}
     */
    function getDisplayDate(_date) {
        var date = moment(_date, 'YYYY-MM-DD');
        return moment(date).format('LL');;
    }

    /**
     * @name getCountrySlides
     * @param $index
     * @returns {*}
     */
    function getCitiesSlides($index) {
        var countries = '';
        if (vm.isMobile) {
            var first = vm.displayCities[$index];
            countries += cityHTML(first);
        } else {
            var max = vm.displayCities.length - 1;
            var first = vm.displayCities[$index];
            var second, third, fourth;
            if ($index === max) {
                second = vm.displayCities[0];
                third = vm.displayCities[1];
                fourth = vm.displayCities[2];
            } else if ($index === max - 1) {
                second = vm.displayCities[max];
                third = vm.displayCities[0];
                fourth = vm.displayCities[1];
            } else if ($index === max - 2) {
                second = vm.displayCities[$index + 1];
                third = vm.displayCities[$index + 2];
                fourth = vm.displayCities[0];
            } else {
                second = vm.displayCities[$index + 1];
                third = vm.displayCities[$index + 2];
                fourth = vm.displayCities[$index + 3];
            }
            countries += cityHTML(first);
            countries += cityHTML(second);
            countries += cityHTML(third);
            countries += cityHTML(fourth);
        }

        return renderHtml(countries);
    }

    function cityHTML(city) {
        if (!city)
            return;
        var hostTemplate = '';
        //Init
        hostTemplate += '<div class="col-xs-12 col-sm-3 col-md-3 host-card-result single cities-card">';
        //Link
        hostTemplate += '<a href="' + city.url + '">';
        //Host result
        hostTemplate += '<div class="host-result">';
        //Header
        hostTemplate += '<div class="city-pic" style="background-image: url(' + city.picture + ')"></div>';
        //Header content
        hostTemplate += '<div class="city-name"><span class="city-name-text text-uppercase">' + city.name + '</span></div>';
        //Close Host Result
        hostTemplate += '</div>';
        //Close link
        hostTemplate += '</a>';
        //host-card-result
        hostTemplate += '</div>';
        return hostTemplate;
    }

    function getSlides($index) {
        var hosts = '';
        if (vm.isMobile) {
            var first = vm.premium_hosts[$index];
            hosts += hostHTML(first);
        } else {
            var max = vm.premium_hosts.length - 1;
            var first = vm.premium_hosts[$index];
            var second, third, fourth;
            if (vm.premium_hosts.length === 1) {
                first = vm.premium_hosts[0];
                hosts += hostHTML(first);
            } else if (vm.premium_hosts.length === 2) {
                first = vm.premium_hosts[0];
                second = vm.premium_hosts[1];
                hosts += hostHTML(first);
                hosts += hostHTML(second);
            } else if (vm.premium_hosts.length === 3) {
                first = vm.premium_hosts[0];
                second = vm.premium_hosts[1];
                third = vm.premium_hosts[2];
                hosts += hostHTML(first);
                hosts += hostHTML(second);
                hosts += hostHTML(third);
            } else {
                if ($index === max) {
                    second = vm.premium_hosts[0];
                    third = vm.premium_hosts[1];
                    fourth = vm.premium_hosts[2];
                } else if ($index === max - 1) {
                    second = vm.premium_hosts[max];
                    third = vm.premium_hosts[0];
                    fourth = vm.premium_hosts[1];
                } else if ($index === max - 2) {
                    second = vm.premium_hosts[$index + 1];
                    third = vm.premium_hosts[$index + 2];
                    fourth = vm.premium_hosts[0];
                } else {
                    second = vm.premium_hosts[$index + 1];
                    third = vm.premium_hosts[$index + 2];
                    fourth = vm.premium_hosts[$index + 3];
                }
                hosts += hostHTML(first);
                hosts += hostHTML(second);
                hosts += hostHTML(third);
                hosts += hostHTML(fourth);
            }
        }
        return renderHtml(hosts);
    }

    function getTranslationLabel(label){
        var translated = vm.translationLabels[label];
        return !!translated ? translated : label;
    }

    /**
     * @name hostHTML
     * @description returns the HTML for a host card
     * @param host
     * @returns {string}
     */
     
    function hostHTML(host) {
        if (!host)
            return;
        var hostTemplate = '';
        hostTemplate += '<div class="col-xs-12 col-sm-3 col-md-3 host-card-result" id="' + host.id_event + '" date="' + host.displayDate + '">';
        hostTemplate += '<div class="host-result">';
        hostTemplate += '<div class="host-card-header summary-card-cover" style="background-image: url(' + host.image + ')">';
        if (host.free) {
            hostTemplate += '<div class="Side_Banner"><span class="Green_Banner">' + vm.view_event_free_text + '</span></div>';
        }
        if (host.soldout) {
            hostTemplate += '<div class="Side_Banner"><span class="Red_Banner">' + vm.event_sold_text + '</span></div>';
        }
        // if (host.soldout) {
        //     hostTemplate += '<div class="host-sold-out"><span class="host-sold-text text-uppercase"><span>' + vm.event_sold_text + '</span></span></div>';
        // }
        hostTemplate += '</div>';
        hostTemplate += '<div class="host-card-body">';
        hostTemplate += '<div class="card-host-info">';
        hostTemplate += '<span class="host-text text-uppercase">' + host.city_name + '</span>';
        hostTemplate += '<span class="host-text">' + vm.getDisplayDate(host.displayDate) + ' | ' + host.open_hour + '</span>';
        hostTemplate += '<span class="host-text text-dark">' + host.title + '</span>';
        hostTemplate += '<span class="host-text">' + vm.hosted_by_text + ' ' + host.first_name + '</span>';
        getTranslationLabel(host.experience[0]);
        if (host.free) {
            hostTemplate += '<span class="host-text"><span class="free-text">' + vm.view_event_free_text + '</span> - <b>' + getTranslationLabel(host.experience[0]) + '</b></span>';
        } else {
            hostTemplate += '<span class="host-text">' + currency_translateFilter(vm.currency) + ' ' + host.price + ' - <b>' + getTranslationLabel(host.experience[0]) + '</b></span>';
        }
        if (!!host.reviews.length) {
            hostTemplate += '<span class="host-text">';
            for (var i = 0; i < host.reviews_average; i++) {
                hostTemplate += '<span class="glyphicon glyphicon-star" aria-hidden="true"></span>';
            }
            hostTemplate += '<span style="margin-left: 5px;">' + host.reviews.length + ' ' + vm.profile_reviews_text + '</span>';
            hostTemplate += '</span>';
        } else {
            hostTemplate += '<span class="host-text">';
            hostTemplate += '<span>' + vm.view_event_no_text + '</span>';
            hostTemplate += '</span>';
        }
        //card-host-info
        hostTemplate += '</div>';
        hostTemplate += '<div class="card-host-pic">';

        hostTemplate += '<div class="host-img" style="background-image: url(' + host.host_image + ')"></div>';

        hostTemplate += '</div>';
        //host-card-body
        hostTemplate += '</div>';
        //host-result
        hostTemplate += '</div>';
        //host-card-result
        hostTemplate += '</div>';
        return hostTemplate;
    }

    /**
     * @name getTranslation
     * @description get the translation of given word
     * @param word
     */
    function getTranslation(word) {
        if (!word)
            return '';
        $translate([word]).then(function (translations) {
            return translations[word];
        });
    }

    /**
     * @name getNumber
     * @description returns an array from a number
     * @param num
     * @returns {any[]}
     */
    function getNumber(num) {
        return new Array(num);
    }

    function init() {
        initGeolocation();
        angular.element('input').focusin(function () {
            var input = $(this);
            input.data('place-holder-text', input.attr('placeholder'));
            input.attr('placeholder', '');
        });
        angular.element('input').focusout(function () {
            var input = $(this);
            input.attr('placeholder', input.data('place-holder-text'));
        });
        //angular.element('#carousel-container').on('swipeleft swiperight', updateSlide);
        vm.isMobile = $(window).width() <= 767;
        translateFate();

        // Meta Tag setting, title and description
        ngMeta.setTitle('Eat with local, Social Eating, Home Chef, Food experience - Italy, France & Denmark and anywhere around the world');
        ngMeta.setTag('description', 'Foodiiz provides you with food experiences with locals in Italy, France, Denmark and all over the world. Come and eat great traditional food around the world & get a taste of international and local street foods. Discover Italy, France, Denmark and the rest of the world through local food experience, a new food concept that brings people on the same table.');
    }

    $(window).resize(function () {
        vm.isMobile = $(window).width() <= 767;
    });

    function translateFate() {
        var translate = [
            'home_search_when',
            'view_event_sold',
            'view_event_hosted_by',
            'view_event_free',
            'profile_reviews_title',
            'view_event_no_reviews',
            'country_SE',
            'country_DK',
            'country_FR',
            'country_IT',
            'country_CR',
            'country_MA',
            'country_IE',
            'country_TN',
            'country_NL',
            'city_paris',
            'city_rome',
            'city_tokyo',
            'city_copenhagen',
            'city_lund',
            'city_berlin',
            'card_halal',
            'card_vegetarian',
            'card_vegan',
            'card_tea',
            'event_experiences_aperitif',
            'event_experiences_breakfast',
            'event_experiences_brunch',
            'event_experiences_cooking',
            'event_experiences_dinner',
            'event_experiences_food',
            'event_experiences_lunch',
            'event_experiences_tea',
            'event_experiences_picnic',
            'event_experiences_chef',
            'event_experiences_market',
            'event_experiences_tour_dine',
            'event_experiences_food_tasting',
            'event_experiences_wine_testing',
            'header_search_text_mobile',
            'header_search_text'
        ];

        $translate(translate).then(function (translations) {
            vm.translationLabels = translations;
            startDate = translations['home_search_when'];
            vm.date = translations['home_search_when'];
            vm.event_sold_text = translations['view_event_sold'];
            vm.hosted_by_text = translations['view_event_hosted_by'];
            vm.view_event_free_text = translations['view_event_free'];
            vm.profile_reviews_text = translations['profile_reviews_title'];
            vm.view_event_no_text = translations['view_event_no_reviews'];
            vm.header_search_text_mobile = translations['header_search_text_mobile'];
            vm.header_search_text = translations['header_search_text'];
            //Tranlating countries
            vm.displayCountries = [
                {
                    'name': translations['country_SE'],
                    'picture': 'assets/images/home/home_sweden.jpg',
                    'url': 'search/Sweden/57.6165963/15.573524899999938/400',
                    'text_color': '#fff'
                },
                {
                    'name': translations['country_DK'],
                    'picture': 'assets/images/home/home_denmark.jpg',
                    'url': 'search/Denmark/55.403692/11.355256999999938/400',
                    'text_color': '#fff'
                },
                {
                    'name': translations['country_FR'],
                    'picture': 'assets/images/home/home_france.jpg',
                    'url': 'search/France/46.768196/2.4326639999999315/400',
                    'text_color': '#fff'
                },
                {
                    'name': translations['country_IT'],
                    'picture': 'assets/images/home/home_italy.jpg',
                    'url': 'search/Italy/42.74048809999999/12.737800300000004/400',
                    'text_color': '#fff'
                },
                {
                    'name': translations['country_CR'],
                    'picture': 'assets/images/home/home_costa_rica.jpg',
                    'url': 'search/Costa%20Rica/9.9280694/-84.09072459999999/400',
                    'text_color': '#fff'
                },
                {
                    'name': translations['country_MA'],
                    'picture': 'assets/images/home/home_marocco.jpg',
                    'url': 'search/Marocco/28.9883659/-10.052749800000015/400',
                    'text_color': '#fff'
                },
                {
                    'name': translations['country_IE'],
                    'picture': 'assets/images/home/home_ireland.jpg',
                    'url': 'search/Irlanda/53.0931067/-8.122803699999963/400',
                    'text_color': '#fff'
                },
                {
                    'name': translations['country_TN'],
                    'picture': 'assets/images/home/home_tunisia.jpg',
                    'url': 'search/Tunísia/34.3547737/9.104115699999966/400',
                    'text_color': '#fff'
                },
                {
                    'name': translations['country_NL'],
                    'picture': 'assets/images/home/home_holland.jpg',
                    'url': 'search/Holland/52.0025516/5.460485199999994/400',
                    'text_color': '#fff'
                }
            ];
            vm.displayCities = [
                {
                    'name': translations['city_lund'],
                    'picture': 'assets/images/home/home_lund.jpg',
                    'url': 'search/Lund,%20Sweden/55.70466010000001/13.191007300000024/400'
                },
                {
                    'name': translations['city_copenhagen'],
                    'picture': 'assets/images/home/home_copenhagen.jpg',
                    'url': 'search/Copenhagen,%20Denmark/55.6760968/12.568337199999974/400'
                },
                {
                    'name': translations['city_rome'],
                    'picture': 'assets/images/home/home_rome2.jpg',
                    'url': 'search/Rome,%20Italy/41.90270080000001/12.496235200000001/400'
                },
                {
                    'name': translations['city_paris'],
                    'picture': 'assets/images/home/home_paris.jpg',
                    'url': 'search/Paris,%20France/48.85661400000001/2.3522219000000177/400'
                }
            ];
            var currentDate = moment(new Date()).format("DD-MM-YYYY");
            var partialUrl = vm.location.replace(' ', '%20') + '/' + vm.searchobject.lat + '/' + vm.searchobject.lng + '/' + 50 + '/' + currentDate;
            vm.displayCategories = [
                {
                    'name': translations['card_halal'],
                    'image': 'assets/images/home/home_halal.jpg',
                    'url': baseURL + 'search/' + partialUrl + '/halal'
                },
                {
                    'name': translations['card_vegetarian'],
                    'image': 'assets/images/home/home_vegetarian.jpg',
                    'url': baseURL + 'search/' + partialUrl + '/vegetarian'
                },
                {
                    'name': translations['card_vegan'],
                    'image': 'assets/images/home/home_vegan.jpg',
                    'url': baseURL + 'search/' + partialUrl + '/vegan'
                },
                {
                    'name': translations['card_tea'],
                    'image': 'assets/images/home/home_teatime.jpg',
                    'url': baseURL + 'search/' + partialUrl + '/tea'
                },
            ];
            //Init translation boolean
            $timeout( function(){
                vm.translationReady = true;
                getPremiumHosts();
            }, 3000);
        });
    }

    function resetUIElements() {
        vm.translationReady = false;
        vm.displayCountries = [];
        vm.displayCities = [];
        vm.displayCategories = [];
        translateFate();
    }

    // from homepage newsletter singup form
    function signNewsletter() {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(vm.email)) {
            UsersService.newsletterSignup(vm.email).then(
                function (response) {
                    if (response.data.data.response === "success") {
                        toaster.pop("success", "Newsletter Signup", "You have successfuly signed up for our Newsletter.", 4000);
                        vm.email = "";
                    }
                    else if(response.data.data.response === "duplicate")
                        toaster.pop("error", "Error", "Your email is already registered for newsletters.", 4000);
                    else
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                },
                function (err) {
                    toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                }
            );
        } else {
            toaster.pop("error", "Error", "Invalid Email Format", 4000);
        }
    }


    $rootScope.$on('languageChanged', resetUIElements);
    $rootScope.$on('currencyChanged', getPremiumHosts);

    init();

}