'use strict';

module.exports = BlogController;

BlogController.$inject = ['$scope', 'BlogService', 'ngMeta', '$sce'];

function BlogController($scope, BlogService, ngMeta, $sce) {
    var vm = this;
    vm.blogPosts = null;
    vm.initDate = moment().format("2019-01-01");
    vm.endDate = moment().format("YYYY-MM-DD");
    vm.init = init;

    vm.renderHtml = renderHtml;
    vm.getDate = getDate;

    /**
     * @name init
     * @description to set the title and description
     */
    function init() {
        ngMeta.setTitle('Willing to Cook local food for people? Contact Us Online & Earn Money');
        ngMeta.setTag('description', 'We love emails almost as much as we love food. So, what are you waiting for? Be a host in your city or region and get an opportunity to meet new locals, tourist over a shared meal experience.');
        getPosts();
    }

    /**
     * @name getPosts
     * @description Gets blog posts
     */
    function getPosts() {
        BlogService.getPosts(vm.initDate, vm.endDate).then(
                function (response) {
                    if (response.data.data.response === 'success') {
                        vm.blogPosts = response.data.data.posts;
                    }
                },
                function (err) {
                    toaster.pop('error', "Error", "Something went wrong getting posts.", 4000);
                }
        );
    }

    function renderHtml(html_code) {
        return $sce.trustAsHtml(html_code);
    }

    /**
     * @name getDate
     * @description Transforms date
     * @param {*} date 
     */
    function getDate(date){
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD-MM-YYYY');
    }

    init();

    $scope.$watch(function watch(scope) {
        return vm.initDate;
    }, function handle(newV, oldV) {
        if (!!newV && newV !== oldV) {
            getPosts();
        }
    }, true);

    $scope.$watch(function watch(scope) {
        return vm.endDate;
    }, function handle(newV, oldV) {
        if (!!newV && newV !== oldV) {
            getPosts();
        }
    }, true);
}